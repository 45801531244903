/**
 *
 * A simple container to pass grid content into.
 * Optional className overrides (TODO: needed?)
 *
 * @example
 * <Grid
 *   className=""
 * >
 *   <GridColumns>...</GridColumns>
 * </Grid>
 *
 */

import React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Grid = ({ className, children }) => {
  // grid toggle
  const [gridIsToggled, setGridIsToggled] = useState(false);
  // show/hide grid
  const toggleGrid = () => {
    setGridIsToggled(!gridIsToggled);
  };
  // g key is pressed. https://stackoverflow.com/a/46123962/14677853 + https://reactjs.org/docs/hooks-effect.html
  // Note: removed callback function from above solution, works on open & close now
  const gKeyPress = event => {
    if (event.keyCode === 71) {
      // setShowMenu(false); // only close
      toggleGrid(); // toggle menu
    }
  };
  // Create listener for pressing g to toggle the menu
  useEffect(() => {
    document.addEventListener("keydown", gKeyPress, false);
    return () => {
      document.removeEventListener("keydown", gKeyPress, false);
    };
  });

  return (
    <div
      className={classNames(
        "relative",
        "grid",
        "grid-cols-12",
        "gap-x-4 lg:gap-x-8",
        "gap-y-4 lg:gap-y-8",
        "w-full",
        // "gap-y-4 md:gap-y-6 lg:gap-y-8",
        className
      )}
    >
      {children}

      {/* Show the grid for design purposes, when pressing "g" */}
      {gridIsToggled && (
        <div
          className={classNames(
            "absolute top-0 right-0 bottom-1 left-0",
            "grid grid-cols-12",
            // Inner gutter:
            "gap-x-4 lg:gap-x-8",
            "gap-y-4 lg:gap-y-8",
            "pointer-events-none"
          )}
        >
          {/* Grid visualisation 🥵 */}
          <div className="relative col-span-1 h-full bg-blue/40 border-red border-l" />
          <div className="relative col-span-1 h-full bg-blue/40 before:w-4 lg:before:w-8 before:h-full before:bg-red/20 before:block before:absolute before:top-0 before:right-full" />
          <div className="relative col-span-1 h-full bg-blue/40 before:w-4 lg:before:w-8 before:h-full before:bg-red/20 before:block before:absolute before:top-0 before:right-full" />
          <div className="relative col-span-1 h-full bg-blue/40 before:w-4 lg:before:w-8 before:h-full before:bg-red/20 before:block before:absolute before:top-0 before:right-full" />
          <div className="relative col-span-1 h-full bg-blue/40 before:w-4 lg:before:w-8 before:h-full before:bg-red/20 before:block before:absolute before:top-0 before:right-full" />
          <div className="relative col-span-1 h-full bg-blue/40 before:w-4 lg:before:w-8 before:h-full before:bg-red/20 before:block before:absolute before:top-0 before:right-full" />
          <div className="relative col-span-1 h-full bg-blue/40 before:w-4 lg:before:w-8 before:h-full before:bg-red/20 before:block before:absolute before:top-0 before:right-full" />
          <div className="relative col-span-1 h-full bg-blue/40 before:w-4 lg:before:w-8 before:h-full before:bg-red/20 before:block before:absolute before:top-0 before:right-full" />
          <div className="relative col-span-1 h-full bg-blue/40 before:w-4 lg:before:w-8 before:h-full before:bg-red/20 before:block before:absolute before:top-0 before:right-full" />
          <div className="relative col-span-1 h-full bg-blue/40 before:w-4 lg:before:w-8 before:h-full before:bg-red/20 before:block before:absolute before:top-0 before:right-full" />
          <div className="relative col-span-1 h-full bg-blue/40 before:w-4 lg:before:w-8 before:h-full before:bg-red/20 before:block before:absolute before:top-0 before:right-full" />
          <div className="relative col-span-1 h-full bg-blue/40 border-r border-red before:w-4 lg:before:w-8 before:h-full before:bg-red/20 before:block before:absolute before:top-0 before:right-full" />
        </div>
      )}
    </div>
  );
};

Grid.defaultProps = {
  className: "",
};

Grid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Grid;
