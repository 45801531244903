import React from "react";
import { graphql } from "gatsby";
import { getBlockContent, getBlockImage } from "../utils/helpers";

// Layout
import Layout from "../components/layout/Layout";
import Accordion from "../components/layout/Accordion";

// Primitives
import Button from "../components/primitives/Button";
import EmojiIcon from "../components/primitives/EmojiIcon";
import Grid from "../components/primitives/Grid";
import ProgramItem from "../components/primitives/ProgramItem";

const Program = ({ data }) => {
  const { nodes: programData = [] } = data.allSanityProgram;
  const { pageTitle, shoutOut, metaInfo, title, slug, _rawContent } =
    data?.sanityPages || {};
  const { programArray = [] } = programData[0] || [{}];

  const metaStructure = {
    title: metaInfo?.header || title,
    someTitle: metaInfo?.someHeader || pageTitle,
    description:
      metaInfo?.description || getBlockContent(_rawContent) || shoutOut,
    fullSlug: slug?.current,
    image: metaInfo?.image || getBlockImage(_rawContent)?.image,
    imageAlt: metaInfo?.image
      ? metaInfo?.imageAlt
      : getBlockImage(_rawContent)?.alt,
  };

  return (
    <Layout metaInfo={metaStructure}>
      <div>
        {/* Page heading */}
        <div
          className="
            flex flex-wrap justify-between
            w-full
            mb-8
          "
        >
          <Grid>
            <div className="col-span-12">
              <h1
                className="
                  text-4xl
                  uppercase
                  motion-safe:animate-symbol-bounce-slow
                "
              >
                {pageTitle} <EmojiIcon name="clap" animated />
              </h1>
            </div>

            <div className="col-span-12 lg:col-span-8 xxl:col-span-6">
              {shoutOut && <p className="font-bold">{shoutOut}</p>}
            </div>
            {/* Spacer */}
            <div className="col-span-12 lg:col-span-4 xxl:col-span-6" />
          </Grid>
        </div>

        {/* <Link to={`/speakers`}>
          <div
            className="
              flex justify-between
              bg-green text-black
              w-full p-4 mb-2
              hover:bg-black hover:text-white"
          >
            <h2 className="text-2xl uppercase">Speakers</h2>
            <h2 className="text-2xl">→</h2>
          </div>
        </Link> */}

        {/* All of the days - thur, fri etc */}
        <Accordion alignLeft>
          {programArray.map(item => (
            <div key={item._key} data-header={item.postTitle} className="mb-2">
              <ProgramItem item={item} />
            </div>
          ))}
        </Accordion>

        <div className="flex justify-center mb-8">
          <Button color="green" size="3xl" href="/workshops">
            WOrkshops →
          </Button>
        </div>
        {/* <div className="mb-24 mt-24 uppercase text-center">
          <a
            href="/program2022"
            className="underline hover:text-green-dark"
          >
            check out last years talks here
          </a>
        </div> */}
      </div>
    </Layout>
  );
};

export default Program;

export const query = graphql`
  query {
    sanityPages(slug: { current: { eq: "program2022" } }) {
      id
      pageTitle
      shoutOut
      _rawContent(resolveReferences: { maxDepth: 10 })
      slug {
        current
      }
      metaInfo {
        description
        header
        someHeader
        imageAlt
        image {
          asset {
            url
          }
        }
      }
    }
    allSanityProgram(filter: { programYear: { eq: "2022" } }) {
      nodes {
        id
        programArray {
          _key
          _type
          postTitle
          postSubtitle
          slotRef {
            _key
            _type
            linkToTalk
            ref {
              ... on SanityTalk {
                _type
                id
                title
                slug {
                  current
                }
                duration
                time
                foredragsholdere {
                  name
                  stilling
                  company
                  slug {
                    current
                  }
                }
                sceneRef {
                  title
                }
              }
              ... on SanityWorkshop {
                _type
                id
                title
                slug {
                  current
                }
                duration
                time
                foredragsholdere {
                  name
                  stilling
                  company
                  slug {
                    current
                  }
                }
                sceneRef {
                  title
                }
              }
              ... on SanityArticle {
                _type
                id
                title
                slug {
                  current
                }
              }
            }
          }
        }
      }
    }
  }
`;
